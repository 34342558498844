
.home {
  .content-info {
    display: none;
  }
}

.content-info {
  padding: 60px 0;
  p {
    float: left;
  }
  
  .nav {
    float: right;
}
  
}


@media (max-width: 668px) {
  
  
 .content-info {
 
  .nav {
    float: none;
}
  
} 
  
}