
.slideshow.history_slider {
    min-height: 500px;
    height: calc(100vh - 185px);
  margin-bottom: 30px;
 .slider {
     min-height: 500px;
    height: calc(100vh - 185px);
}
}

.slideshow.history_slider .item {
   
    min-height: 500px;
    height: calc(100vh - 185px);
   
}



.slideshow {
    position: relative;
    z-index: 1;
    height: calc(100vh - 15px);
    max-width: 100%;
    margin: auto;
    .slider {
        min-height: 500px;
        height: calc(100vh - 15px);
        width: 100%;
    }
    .slider-track {
        // transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    }
    .item {
        height: 100%;
        position: relative;
        z-index: 1;
        min-height: 500px;
        height: calc(100vh - 15px);
        width: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        display: table;
        &.slick-active {} &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            //background: linear-gradient(rgba(72, 72, 72, 0.09),hsla(0, 0%, 0%, 0.42));
            z-index: 1;
        }
        .desc-txt {
            display: table-cell;
            vertical-align: middle;
            text-align: left;
            position: relative;
            z-index: 21;
            position: absolute;
            bottom: 0;
            background: rgba(255, 255, 255, 0.75);
            .entry {
                max-width: 560px;
                margin: auto;
                padding: 20px;
                h2 {
                    color: $drk;
                    font-size: 28px;
                    line-height: 1.3;
                    margin: 0 0 5px;
                    text-transform: uppercase;
                }
                p {
                    font-size: 16px;
                    line-height: 1.4;
                    margin: 0;
                    color: $drk;
                }
                a {
                    display: block;
                    padding: 0;
                    text-align: left;
                    margin: 5px 0 0;
                    i {
                        position: relative;
                        left: 5px;
                        top: 1px;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: inherit;
    width: inherit;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: -28px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 10;
}
.slick-next {
    right: 0;
}
.slick-prev {
    left: inherit;
    right: 80px;
}
.slick-prev:before,
.slick-next:before {
    font-family: "Ionicons";
    font-size: 30px;
    line-height: 1;
    color: #fff;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-next:before {
    content: "\f125";
    padding-right: 30px;
}
.slick-prev:before {
    content: "\f124";
    padding-left: 30px;
}
.arrow-holder {
    display: block;
    position: absolute;
    top: 76px;
    right: 0px;
}
.slick-arrow {
    z-index: 20;
    background-color: transparent!important;
    width: 50%;
    height: 60%;
    opacity: 0;
    top: 50%;
    bottom: 0;
}
.slick-next {
    right: 0;
    display: inline-block;
    line-height: 1!important;
    text-align: right;
    vertical-align: middle;
    border-radius: 0;
    margin-right: -39px;
    -webkit-transition: margin-left .5s, background-position .5s, opacity .2s;
    -o-transition: margin-left .5s, background-position .5s, opacity .2s;
    transition: margin-left .5s, background-position .5s, opacity .2s;
}
.slick-next:hover {
    opacity: 1;
    margin-right: 0;
    -webkit-transition: margin-right .3s, background-position .3s, opacity .8s;
    -o-transition: margin-right .3s, background-position .3s, opacity .8s;
    transition: margin-right .3s, background-position .3s, opacity .8s;
}
.slick-prev:hover {
    opacity: 1;
    margin-left: 0;
    -webkit-transition: margin-left .3s, background-position .3s, opacity .8s;
    -o-transition: margin-left .3s, background-position .3s, opacity .8s;
    transition: margin-left .3s, background-position .3s, opacity .8s;
}
.slick-prev {
    right: inherit;
    display: inline-block;
    line-height: 1!important;
    text-align: left;
    vertical-align: middle;
    border-radius: 0;
    left: 0;
    margin-left: -39px;
    -webkit-transition: margin-left .5s, background-position .5s, opacity .2s;
    -o-transition: margin-left .5s, background-position .5s, opacity .2s;
    transition: margin-left .5s, background-position .5s, opacity .2s;
}
/* Dots */

.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    position: absolute;
    bottom: -45px;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}
//page-header
.page-header {
    margin-top: 100px;
    h1 {
        margin: 0 0 30px;
    }
}
//about
figcaption {
    margin: 5px 0 0;
    display: block;
    span {
        font-size: 12px;
        line-height: 1.3;
        display: block;
        color: #5a5a5a;
    }
}
.gallery {
    margin: 45px 0;
    .item {
        margin-bottom: 30px;
        img {
            width: 100%;
            height: inherit;
        }
    }
}
aside {
    .entry {
        h3 {
            font-size: 24px;
            line-height: 1;
            text-transform: uppercase;
            margin: 0 0 20px;
        }
    }
    ul {
        margin: 0 0 20px;
        padding: 0;
        li {
            display: block;
            list-style: none;
        }
    }
  
  .btn-primary {
    color: #fff;
    background-color: #404040;
    border-radius: 0;
    margin: 0 0 20px;
    border: none;
}
  
  
}
.section-title {
    h2 {
        margin: 0 0 30px;
        font-size: 2.5rem;
    }
}
//responsive
@media (max-width: 668px) {
    .h1,
    h1 {
        font-size: 1.8rem;
    }
    .h2,
    h2 {
        font-size: 1.4rem;
    }
    .h3,
    h3 {
        font-size: 1.2rem;
    }
  
  
  .slideshow.history_slider {
      min-height: inherit;
     height: inherit;
  margin-bottom: 30px;
 .slider {
     min-height: inherit;
    height: inherit;
}
}

.slideshow.history_slider .item {
   
        min-height:240px;
        height: 220px;
        background-position: 50% 0px;
   
}

  
  
 
  
  .slideshow {
     height: inherit;
    
    .slider {
        min-height: inherit;
        height: inherit;
    
        }
    
     .slick-arrow  {
      display: none !important;
      }
    
        .item {
        min-height: 420px;
        height: calc(100vh - 145px);
        background-position: 50% 70px;
        background-size: contain;
          .desc-txt .entry {
          max-width: 90%;
          margin: 0;
          padding: 20px 0;
          }
        .desc-txt .entry h2 {
            font-size: 20px;
            line-height: 1.3;
        }
        .desc-txt .entry p {
            font-size: 14px;
            line-height: 1.4;
        }
    }
  }
}


@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 667px) 
and (orientation : landscape) { 

 .slideshow .item {
        min-height: 460px;
        height: calc(100vh);
        background-position: 50% 70px;
        background-size: contain;
        
    }




}
