// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


.wpcf7-form-control {
  border: none;
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-bottom: 1px solid #cecece;
   
  
}


.wpcf7-form-control:focus {
    border: none;
    outline: 0;
    background-color: #f9f9f9;
    border-bottom: 1px solid #1d87f5;
}
  

.wpcf7-form-control.wpcf7-submit {
  background: $blue;
  float:left;
  border: none;
  padding: 14px 30px;
  color: #fff;
}
  