.home {
  .header {
    border: none;
  }
}
.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 0 15px;
    z-index: 9;
    background: #fff;
   border-bottom: 1px solid #cecece;
  
  .brand {
    font-family: Open Sans Condensed,sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    color: #000;
    letter-spacing: 1px;
    font-size: 30px;
    float: left;
    &:hover {
      text-decoration: none;
    }
    
    small {
    display: block;
    font-weight: 100;
    font-size: 13px;
    font-family: Open Sans,sans-serif;
    color: #757575;
    letter-spacing: 0.9px;
    }
    
  }
  
}

.nav-primary {
  float: right;
  .nav {
    li {
      display: inline-block;
      a {
       
        padding-left: 12px;
        padding-right: 12px;
        color: $drk;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        text-decoration: none;
        @include ease_out();
        &:hover {
          color: $blue;
        }
      }
      &:first-child {
        a {
           padding-left: 0px;
        }
      }
      &:last-child {
        a {
           padding-right: 0px;
        }
      }
      }
    .active a {
        color: #087cf4;
      }
  }
}