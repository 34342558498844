// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$drk: #404040;

$blue:#087cf4;

@mixin ease_out() {
        -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
}