body, html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}



body {
   font-family: 'Open Sans', sans-serif;
  font-size: 14px;
    line-height: 22px;
    font-weight: 100;
    background: #fff;
}


h1, h2, h3, h4 {
  font-family: 'Open Sans Condensed', sans-serif;

    letter-spacing: 1px;
    font-weight: 500;
}


a {
 color: $blue;
}


#page {
  overflow: hidden;
}

:focus, button:focus {
    outline: none;

}



 .single_slider .slick-slide {
      display: none;
    }
    .single_slider .slick-slide:first-child {
      display: block;
    }
    .single_slider.slick-initialized .slick-slide {
      display: block;
    }


.main .social-networks.org-social a {
    background: $drk;
    border: 1px solid $drk;
    @include ease_out();
    &:hover {
      background: #fff;
        color: $drk;
        border: 1px solid $drk;
    }
}
.main .social-networks {
    .small-social {
        a {
            color: #fff;
            width: 35px;
            height: 35px;
            line-height: 35px;
            font-size: 16px;
        }
    }
}


.main {
    .social-networks {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
        }
        a {
            color: #fff;
            width: 42px;
            height: 42px;
            background: $drk;
             border: 1px solid $drk;
            display: block;
            line-height: 42px;
            border-radius: 50%;
            font-size: 18px;
            text-align: center;
             @include ease_out();
            &:hover {
              background: #fff;
                color: $drk;
                border: 1px solid $drk;
            }
        }
    }
}

 .hamburger-holder {
    display: none;
  }

#main-nav {
  display: none;
}


@media (max-width: 768px) {

  .header {
    .nav-primary {
      display: none;
    }
  }

  #main-nav {
  display: block;
}

  .hamburger-holder {
    display: block;
  }


.noscroll .hamburger-menu {
  opacity: 0;
}

  body.navigation-is-open  {
    overflow: hidden;

    .header {
      border: none;
    }
  }

.hamburger-menu {
  width: 32px;
  height: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  border: none;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &.menu {
    &:after, &:before, span {
      width: 100%;
      height: 2px;
      border-radius: 0;
      position: absolute;
      display: block;
      background: $drk;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
    &:after, &:before {
      content: "";
      transition: 0.3s width 0.4s;
    }
    &:after {
      top: 0px;
      left: 0px;
      margin-top: 0px;
    }
    &:before {
      bottom: 0px;
      right: 0px;
      margin-bottom: 0px;
    }
    span {
      left: 0;
      top: 50%;
      margin-top: -1px;
      transition: transform 0.3s;
      -webkit-transition: transform 0.3s;
      &:before {
        content: "";
        height: 2px;
        border-radius: 0;
        width: 100%;
        background: $drk;
        position: absolute;
        left: 0px;
        transition: transform 0.3s;
        -webkit-transition: transform 0.3s;
      }
    }
    &.active {
      &:after {
        transition: all 0.2s;
        width: 0%;
        left: 0px;
      }
      &:before {
        transition: all 0.2s;
        width: 0%;
        right: 0px;
      }
      span {
        background: $drk;
        transform: rotate(45deg);
        transition: 0.3s transform 0.4s;
        -webkit-transform: rotate(45deg);
        -webkit-transition: 0.3s transform 0.4s;
        &:before {
          background:$drk;
          transform: rotate(-90deg);
          transition: 0.3s transform 0.4s;
          -webkit-transform: rotate(-90deg);
          -webkit-transition: 0.3s transform 0.4s;
        }
      }
    }
  }
}

.submenuItems {
  margin: 0 0 0 20px;
  padding: 4px 0 0;
}

.menu-nav .open .ion-android-add:before {
  content: "\f2f4";
}

.main-nav {
  position: fixed;
  z-index: 8;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: #fff;
  -webkit-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  .navigation-wrapper {
    /* all navigation content */
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 60px 0px;
    .inner-nav {
      margin-top: 20px;
    }
    .social-list {
      margin-top: 35px;
      ul {
        margin: 0;
        padding: 0;
        text-align: left;
        li a {
          color: #fff;
          &:hover {
            color: #fff;
            span {
              background: #000;
            }
          }
          span {
            width: 40px;
            height: 40px;
            background: rgba(0, 0, 0, 0);
            display: block;
            line-height: 42px;
            border: 2px solid #fff;
            border-radius: 1px;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            text-align: center;
          }
          i {
            font-size: 22px;
            line-height: 1;
          }
        }
      }
    }
    .menu-nav {
      padding: 0;
      margin: 0;
      li {
        display: block;
        margin: 0 0 10px;
        font-size: 24px;
        line-height: 1.1;
        /* letter-spacing: .1px; */
        text-align: left;
        a {
          color: $drk;
        }
      }
    }
  }
}

.navigation-is-open .main-nav {
    left: 0;
}
.no-js #page {
    height: auto;
    overflow: visible;
}
.no-js .main-nav {
    position: static;
    visibility: visible;
}
.no-js .main-nav .navigation-wrapper {
    height: auto;
    overflow: visible;
    padding: 100px 5%;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

  }


@media (max-width: 576px) {




}
